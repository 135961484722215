const toolDisplayMapping = {
    'car_stock_search': 'Sto verificando nel nostro stock',
    'product_search': 'Ricerca prodotto',
    'order_tracking': 'Tracciamento ordine',
    'customer_support': 'Supporto clienti',
    'recommendation_engine': 'Suggerimento prodotti',
    'price_checker': 'Controllo prezzi',
    'inventory_check': 'Verifica disponibilità',
    'payment_processing': 'Elaborazione pagamento',
    'return_request': 'Richiesta di reso',
    'shipping_calculator': 'Calcolo spese di spedizione',
    'wishlist_manager': 'Gestione lista desideri',
    'coupon_validator': 'Verifica coupon',
    'size_guide': 'Guida alle taglie',
    'product_comparison': 'Confronto prodotti',
    'gift_wrapping': 'Confezione regalo',
    'loyalty_program': 'Programma fedeltà',
    'newsletter_subscription': 'Iscrizione newsletter',
    'product_review': 'Recensione prodotto',
    'store_locator': 'Trova negozio',
    'virtual_fitting_room': 'Camerino virtuale'
  };
  
  export default toolDisplayMapping;
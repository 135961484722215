import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { TextField, IconButton, Paper, Badge, Box } from '@mui/material';
import { 
  Send as SendIcon, 
  AttachFile as AttachFileIcon,
  InsertDriveFile as FileIcon,
  Favorite as FavoriteIcon
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import WishlistPopup from './WishlistPopup';
import CustomThemeProvider from './ThemeWrapper';

const FooterContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background-color: ${({ theme }) => theme.colors.footer.background};
  padding: ${({ theme }) => theme.customSpacing.footerPadding};
  position: sticky;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.customSpacing.footerPaddingMobile};
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-size: ${({ theme }) => theme.typography.inputFontSize};
    border-radius: ${({ theme }) => theme.shape.inputBorderRadius};
    padding: ${({ theme }) => theme.customSpacing.inputPadding};
    background-color: ${({ theme }) => theme.colors.footer.inputBackground};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    transition: all 0.3s ease;
    &.Mui-focused {
      background-color: ${({ theme }) => theme.colors.footer.inputBackgroundFocused};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.footer.inputFocusShadow};
    }
  }
`;

const ActionButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.footer.buttonBackground} !important;
  color: ${({ theme }) => theme.colors.footer.buttonColor} !important;
  width: ${({ theme }) => theme.sizing.buttonSize} !important;
  height: ${({ theme }) => theme.sizing.buttonSize} !important;
  margin-right: ${({ theme }) => theme.customSpacing.buttonMargin} !important;
  &:hover {
    background-color: ${({ theme }) => theme.colors.footer.buttonBackgroundHover}!important;
  }
`;

const ChatFooter = ({ onSendMessage, onSendFile, isLoading, wishlistItems, onWishlistUpdate }) => {
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleSend = () => {
    if (message.trim() || selectedFile) {
      if (selectedFile) {
        onSendFile(selectedFile, message);
        setSelectedFile(null);
      } else {
        onSendMessage(message);
      }
      setMessage('');
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        setSelectedFile(file);
      } else {
        alert('Invalid file type or size. Please select a JPEG, PNG, or PDF file under 5MB.');
      }
    }
  };

  const toggleWishlist = () => {
    setIsWishlistOpen(!isWishlistOpen);
  };

  return (
    <CustomThemeProvider>
      <FooterContainer elevation={3}>
        <InputContainer>
          <StyledTextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder={process.env.REACT_APP_CHAT_INPUT_PLACEHOLDER}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            disabled={isLoading}
            multiline
            rows={1}
            sx={{ flex: 1, marginRight: '15px', maxHeight: 'none' }}
          />
          <input 
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            accept=".jpg,.jpeg,.png"
          />
          <ActionButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => fileInputRef.current.click()}
            disabled={isLoading}
            aria-label="Attach file"
          >
            <Badge badgeContent={selectedFile ? <FileIcon fontSize="small" /> : null} color="secondary">
              <AttachFileIcon />
            </Badge>
          </ActionButton>
          <AnimatePresence>
            {message.trim() || selectedFile ? (
              <ActionButton
                component={motion.button}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleSend}
                disabled={isLoading}
                aria-label="Send message"
              >
                <SendIcon />
              </ActionButton>
            ) : (
              <ActionButton
                component={motion.button}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={toggleWishlist}
                disabled={isLoading}
                aria-label="Open Wishlist"
              >
                <Badge badgeContent={wishlistItems.length > 0 ? wishlistItems.length : null} color="secondary">
                  <FavoriteIcon />
                </Badge>
              </ActionButton>
            )}
          </AnimatePresence>
        </InputContainer>
      </FooterContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </CustomThemeProvider>
  );
};

export default ChatFooter;
import React from "react";
import ChatWrapper from "./components/ChatWrapper";
import "./App.css";

function App() {
  

  return (
    <>
      <ChatWrapper />
    </>
  );
}

export default App;
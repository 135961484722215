import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
  Chip,
  Zoom,
  Fade,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EventIcon from '@mui/icons-material/Event';
import { motion, AnimatePresence } from 'framer-motion';
import VehicleDialog from './VehicleDialog';
import { fetchVehicleData } from '../api/fetchVehicleData';
import { themebody } from './theme';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${props => props.theme.colors.background.default};
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
`;

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};


const StyledDialogContent = styled(DialogContent)`
  padding: 24px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background.default};
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary.main};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.primary.dark};
  }
  scroll-behavior: smooth;
`;

const StyledCard = styled(motion.div)`
  background-color: ${props => props.theme.colors.background.paper};
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-bottom: 24px;
  &:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
  }
`;

const GlowButton = styled(Button)`
  position: relative;
  overflow: hidden;
  background: ${props => props.theme.colors.primary.dark} !important;
  color: white !important;
  &::after {
    content: "";
    position: absolute;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255,255,255,0.5) 50%, rgba(229, 172, 142, 0));
    transform: rotateZ(60deg) translate(-5em, 7.5em);
    animation: shimmer 3s infinite;
    z-index: 1;
  }
  @keyframes shimmer {
    0% {
      transform: rotateZ(60deg) translate(-5em, 7.5em);
    }
    100% {
      transform: rotateZ(60deg) translate(5em, -7.5em);
    }
  }
`;

const WishlistPopup = ({ open, handleClose, wishlistItems, onWishlistUpdate, onSendMessage }) => {
  const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);

  const handleOpenVehiclePopup = async (vehicleId) => {
    try {
      const data = await fetchVehicleData(vehicleId);
      setSelectedVehicleData(data);
      setVehicleDialogOpen(true);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  };

  const handleCloseVehiclePopup = () => {
    setVehicleDialogOpen(false);
    setSelectedVehicleData(null);
  };

  const handleBookVisit = (message) => {
    setVehicleDialogOpen(false);
    setSelectedVehicleData(null);
    onSendMessage(message);
    handleClose();
  };

  const handleRemoveFromWishlist = async (item) => {
    try {
      await onWishlistUpdate(item.messageId, item.vehicleid, false);
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  return (
    <ThemeProvider theme={themebody}>
      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        TransitionComponent={Zoom}
        transitionDuration={400}
      >
        <DialogTitle sx={{
          background: `linear-gradient(45deg, ${themebody.colors.primary.main}, ${themebody.colors.primary.dark})`,
          color: themebody.colors.text.primary,
          padding: '24px',
        }}>
          <Box display="flex" alignItems="center">
            <FavoriteIcon sx={{ marginRight: 1, fontSize: '2rem', color: "white" }} />
            <Typography variant="h4" component="span" fontWeight="400" color="white">La tua Wishlist</Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: "white",
              background: 'rgba(255, 255, 255, 0.2)',
              '&:hover': {
                background: 'rgba(255, 255, 255, 0.3)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <StyledDialogContent>
          <AnimatePresence>
            {wishlistItems.length === 0 ? (
              <Fade in={true}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '300px',
                  textAlign: 'center',
                }}>
                  <Typography variant="h5" color="text.secondary" gutterBottom>
                    La tua wishlist è vuota
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Aggiungi alcuni veicoli per iniziare la tua collezione!
                  </Typography>
                  <GlowButton
                    variant="contained"
                    size="large"
                    sx={{ marginTop: 3 }}
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleClose}
                  >
                    Esplora Veicoli
                  </GlowButton>
                </Box>
              </Fade>
            ) : (
              wishlistItems.map((item, index) => (
                <StyledCard
                  key={item.vehicleid}
                  layout
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.4, delay: index * 0.1 }}
                >
                  <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100%' }}>
                    <Box sx={{ position: 'relative', width: { xs: '100%', md: '40%' }, height: { xs: '200px', md: 'auto' } }}>
                      <CardMedia
                        component="img"
                        sx={{
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                        image={item.image || 'https://aitomotivelab.com/media/popup_chat/non_disp.jpeg'}
                        alt={item.title}
                      />
                      {item.badge && (
                        <Chip
                          label={(item.badge)}
                          color="primary"
                          sx={{
                            position: 'absolute',
                            top: 16,
                            left: 16,
                            zIndex: 1,
                            fontWeight: '400',
                            fontSize: '1rem',
                            height: '28px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          }}
                        />
                      )}
                    </Box>
                    <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: { xs: 2, md: 3 } }}>
                      <Box>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: themebody.colors.text.primary }}>{item.title}</Typography>
                        <Box display="flex" alignItems="center" gap={2}>
                          {item.description1 && parseFloat(item.description1) > 0 && (
                            <Box display="flex" alignItems="center" marginBottom={1.5}>
                              <DirectionsCarIcon sx={{ marginRight: 0.5, fontSize: '1.2rem', color: themebody.colors.primary.main }} />
                              <Typography variant="body2" color="text.secondary">
                                {formatPrice(item.description1)} {item.description1String}
                              </Typography>
                            </Box>
                          )}
                          {item.description3 && parseInt(item.description3) > 0 && (
                            <Box display="flex" alignItems="center" marginBottom={1.5}>
                              <CalendarTodayIcon sx={{ marginRight: 0.5, fontSize: '1.2rem', color: themebody.colors.primary.main }} />
                              <Typography variant="body2" color="text.secondary">
                                Anno: {item.description3}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {item.description2 && (
                          <Box display="flex" alignItems="center" marginBottom={1.5}>
                            <EuroSymbolIcon sx={{ marginRight: 1, fontSize: '1.2rem', color: themebody.colors.primary.main }} />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: themebody.colors.primary.main }}>
                              {formatPrice(item.description2)} {item.description2String} 
                            </Typography>
                          </Box>
                        )}
                        {item.description4 > 0 && parseFloat(item.description4) > parseFloat(item.description2) && (
                          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration: 'line-through',
                                color: themebody.colors.text.secondary,
                                marginRight: '8px',
                                marginLeft: '8px',
                              }}
                            >
                              <EuroSymbolIcon style={{ marginRight: '2px', fontSize: '0.8rem' }} />
                              {formatPrice(item.description4)}
                            </Typography>
                            <Chip
                              label={`-${Math.round((1 - parseFloat(item.description2) / parseFloat(item.description4)) * 100)}%`}
                              color="error"
                              size="small"
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '0.8rem',
                              }}
                            />
                          </Box>
                        )}
                        {item.promotion && (
                          <Box
                            sx={{
                              mt: 2,
                              p: 2,
                              background: 'linear-gradient(90deg, rgba(229,172,142,1) 0%, rgba(146,168,209,1) 100%)',
                              borderRadius: '12px',
                              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            }}
                          >
                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: themebody.colors.text.primary, marginBottom: 0.5 }}>
                              Promozione Speciale:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item.descrizione_economica_promozione_ai}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="stretch" marginTop={3} gap={2}>
                        <GlowButton
                          startIcon={<EventIcon />}
                          onClick={() => handleBookVisit("Vorrei prenotare una visita per: " + item.title)}
                          size="large"
                          fullWidth
                        >
                          Prenota visita auto
                        </GlowButton>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<DirectionsCarIcon />}
                            href={item.url}
                            target="_blank"
                            size="large"
                            fullWidth
                          >
                            Apri Pagina
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleOpenVehiclePopup(item.vehicleid)}
                            size="large"
                            fullWidth
                          >
                            Dettagli
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<FavoriteIcon />}
                            onClick={() => handleRemoveFromWishlist(item)}
                            size="large"
                            fullWidth
                          >
                            Rimuovi
                          </Button>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </StyledCard>
              ))
            )}
          </AnimatePresence>
        </StyledDialogContent>
      </StyledDialog>
      <VehicleDialog
        open={vehicleDialogOpen}
        handleClose={handleCloseVehiclePopup}
        vehicleData={selectedVehicleData}
      />
    </ThemeProvider>
  );
};

export default WishlistPopup;

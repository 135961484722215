import React, { useState, useEffect } from "react";
import styled, { keyframes, css, ThemeProvider } from "styled-components";
import { FaRegSmile, FaRegFrown, FaRegMeh, FaExclamationTriangle, FaPaperPlane } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { handleVote } from '../api/api';
import { themebody } from './theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px) rotate(-5deg); }
  50% { transform: translateX(5px) rotate(5deg); }
  75% { transform: translateX(-5px) rotate(-5deg); }
  100% { transform: translateX(0) rotate(0); }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(5px);
  animation: ${fadeIn} 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
`;

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 500px;
  width: 100%;
  position: relative;
  z-index: 2001;
  color: ${props => props.theme.colors.text.primary};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: ${props => props.theme.colors.text.primary};
  z-index: 2002;
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary.main};
    transform: rotate(90deg) scale(1.2);
  }
`;

const EmojiDisplay = styled.div`
  font-size: 80px;
  transition: all 0.5s ease;
  animation: ${float} 3s ease-in-out infinite;

  &:hover {
    transform: scale(1.2) rotate(15deg);
  }
`;

const CategorySelect = styled.select`
  padding: 12px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  font-size: 16px;
  color: ${props => props.theme.colors.text.primary};
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;

  ${props => props.hasError && css`
    border-color: ${props.theme.colors.error};
    animation: ${shake} 0.5s ease-in-out;
  `}

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary.main};
    box-shadow: 0 0 10px ${props => props.theme.colors.primary.main}80;
  }

  option {
    background-color: ${props => props.theme.colors.background.paper};
  }
`;

const CommentInput = styled.textarea`
  padding: 12px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  resize: none;
  width: 100%;
  font-size: 16px;
  color: ${props => props.theme.colors.text.primary};
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary.main};
    box-shadow: 0 0 10px ${props => props.theme.colors.primary.main}80;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: ${props => props.theme.colors.primary.main};
  color: ${props => props.theme.colors.text.primary};
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    background-color: ${props => props.theme.colors.primary.dark};
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  animation: ${fadeIn} 0.3s ease-out;
`;

const Title = styled.h2`
  font-size: 28px;
  color: ${props => props.theme.colors.text.primary};
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
`;

const FeedbackPopup = ({ isOpen, onRequestClose, onSubmit, selectedEmoji, messageId }) => {
  const [category, setCategory] = useState("");
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      setCategory("");
      setComment("");
      setError("");
    }
  }, [isOpen]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setError("");
  };
  const handleCommentChange = (e) => setComment(e.target.value);

  const handleSubmit = async () => {
    if (!category) {
      setError("Seleziona una categoria per procedere");
      return;
    }

    try {
      const vote = selectedEmoji === 'smile' ? '1' : selectedEmoji === 'meh' ? '0' : '-1';
      await handleVote(messageId, vote, category, comment);
      onSubmit({ category, comment });
      onRequestClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError("Si è verificato un errore. Riprova più tardi.");
    }
  };

  const renderSelectedEmoji = () => {
    switch (selectedEmoji) {
      case "smile":
        return <FaRegSmile color="#ffd700" />;
      case "meh":
        return <FaRegMeh color="#ffa500" />;
      case "frown":
        return <FaRegFrown color="#ff4500" />;
      default:
        return null;
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ThemeProvider theme={themebody}>
      <PopupOverlay>
        <PopupContainer>
          <CloseButton onClick={onRequestClose} aria-label="Close feedback popup"><AiOutlineClose /></CloseButton>
          <Title>Fornisci un Feedback</Title>
          <EmojiDisplay>{renderSelectedEmoji()}</EmojiDisplay>
          <CategorySelect 
            value={category} 
            onChange={handleCategoryChange}
            hasError={!!error}
            aria-label="Seleziona categoria"
          >
            <option value="">Seleziona categoria</option>
            <option value="Assistenza clienti">Assistenza clienti</option>
            <option value="Prenotazioni">Prenotazioni</option>
            <option value="Ricerca Auto">Ricerca Auto</option>
            <option value="Ricerca Promozioni">Ricerca Promozioni</option>
            <option value="Raccolta lead">Raccolta lead</option>
            <option value="Altro">Altro</option>
          </CategorySelect>
          {error && (
            <ErrorMessage>
              <FaExclamationTriangle />
              {error}
            </ErrorMessage>
          )}
          <CommentInput
            value={comment}
            onChange={handleCommentChange}
            rows="4"
            placeholder="Il tuo commento (opzionale)"
            aria-label="Commento"
          />
          <SubmitButton onClick={handleSubmit} aria-label="Invia feedback">
            <FaPaperPlane /> Invia Feedback
          </SubmitButton>
        </PopupContainer>
      </PopupOverlay>
    </ThemeProvider>
  );
};

export default FeedbackPopup;
import React from 'react';
import { Box, Typography, Button, CircularProgress, Fade } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import SyncIcon from '@mui/icons-material/Sync';
import { motion } from 'framer-motion';

const ConnectionStatus = ({ 
  isConnected, 
  isReconnecting, 
  reconnectCountdown, 
  reconnectAttempts, 
  MAX_RECONNECT_ATTEMPTS, 
  onManualReconnect 
}) => {
  const getStatusColor = () => {
    if (isConnected) return '#4CAF50';
    if (isReconnecting) return '#FFC107';
    return '#F44336';
  };

  const getStatusIcon = () => {
    if (isConnected) return <WifiIcon sx={{ fontSize: 48 }} />;
    if (isReconnecting) return <SyncIcon sx={{ fontSize: 48 }} />;
    return <WifiOffIcon sx={{ fontSize: 48 }} />;
  };

  const getStatusText = () => {
    if (isConnected) return 'Connesso';
    if (isReconnecting) return 'Riconnessione';
    return 'Disconnesso';
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        p: 3,
        borderRadius: 4,
        bgcolor: 'background.paper',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        maxWidth: 300,
        width: '100%',
      }}
    >
      <motion.div
        animate={{ rotate: isReconnecting ? 360 : 0 }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      >
        <Box 
          sx={{ 
            width: 100,
            height: 100,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: getStatusColor(),
            color: 'white',
            transition: 'background-color 0.3s ease',
          }}
        >
          {getStatusIcon()}
        </Box>
      </motion.div>

      <Typography variant="h5" sx={{ fontWeight: 'bold', color: getStatusColor() }}>
        {getStatusText()}
      </Typography>

      {isReconnecting && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width: '100%' }}>
          <Typography variant="body2">
            Tentativo {reconnectAttempts} di {MAX_RECONNECT_ATTEMPTS}
          </Typography>
          <Box sx={{ position: 'relative', width: '100%', height: 4, bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: 2 }}>
            <Fade in={true} style={{ transitionDelay: '300ms' }}>
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  bgcolor: getStatusColor(),
                  borderRadius: 2,
                  width: `${(reconnectAttempts / MAX_RECONNECT_ATTEMPTS) * 100}%`,
                  transition: 'width 0.3s ease-in-out',
                }}
              />
            </Fade>
          </Box>
          <Typography variant="body2">
            Prossimo tentativo in {reconnectCountdown}s
          </Typography>
        </Box>
      )}

      {!isConnected && !isReconnecting && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<SyncIcon />}
          onClick={onManualReconnect}
          sx={{ 
            mt: 2, 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          Riprova Connessione
        </Button>
      )}

      {isConnected && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <CircularProgress size={24} thickness={4} sx={{ color: getStatusColor() }} />
        </motion.div>
      )}
    </Box>
  );
};

export default ConnectionStatus;
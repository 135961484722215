import React, { useState } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import { EmojiObjects, ExpandMore, ExpandLess } from '@mui/icons-material';
import { motion } from 'framer-motion';

const AccessorySection = ({ title, accessories, expanded, onToggle }) => {
  if (accessories.length === 0) return null;

  // Function to split accessories into three columns
  const splitIntoColumns = (accessories) => {
    const columnLength = Math.ceil(accessories.length / 3);
    return [
      accessories.slice(0, columnLength),
      accessories.slice(columnLength, 2 * columnLength),
      accessories.slice(2 * columnLength)
    ];
  };

  const columns = splitIntoColumns(accessories);
  const displayedColumns = expanded ? columns : columns.map(col => col.slice(0, 3));

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" color="secondary" sx={{ display: 'flex', alignItems: 'center' }}>
          <EmojiObjects sx={{ mr: 1 }} /> {title}
        </Typography>
        <Button
          onClick={onToggle}
          endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          sx={{ textTransform: 'none', borderRadius: '20px' }}
        >
          {expanded ? 'Nascondi' : 'Mostra tutto'}
        </Button>
      </Box>
      <Grid container spacing={3}>
        {displayedColumns.map((column, colIndex) => (
          <Grid item xs={12} md={4} key={colIndex}>
            {column.map((accessory, index) => (
              <Typography key={index} variant="body2" sx={{ py: 1, borderBottom: '1px solid #e0e0e0' }}>
                {accessory}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const Accessories = ({ standardAccessories, optionalAccessories }) => {
  const [standardExpanded, setStandardExpanded] = useState(false);
  const [optionalExpanded, setOptionalExpanded] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <AccessorySection 
        title="Dotazioni di serie"
        accessories={standardAccessories}
        expanded={standardExpanded}
        onToggle={() => setStandardExpanded(!standardExpanded)}
      />

      <AccessorySection 
        title="Optional inclusi"
        accessories={optionalAccessories}
        expanded={optionalExpanded}
        onToggle={() => setOptionalExpanded(!optionalExpanded)}
      />
    </motion.div>
  );
};

export default Accessories;

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import { themebody } from './theme';

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px; // Using a fixed value instead of theme.spacing
`;

const Dot = styled(motion.button)`
  width: ${props => props.isActive ? '14px' : '10px'};
  height: ${props => props.isActive ? '14px' : '10px'};
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  background: ${props => 
    props.isActive
      ? `linear-gradient(45deg, ${props.theme.colors.primary.main}, ${props.theme.colors.primary.dark})`
      : props.theme.colors.borderColor};
  box-shadow: ${props => props.isActive ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:focus-visible {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.main};
  }
`;

const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const NavigationDots = ({ items, currentIndex, handleDotClick }) => (
  <ThemeProvider theme={themebody}>
    <DotContainer>
      {items.map((item, index) => (
        <Dot
          key={index}
          isActive={currentIndex === index}
          onClick={() => handleDotClick(index)}
          aria-label={`Go to slide ${index + 1}`}
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.9 }}
        >
          <VisuallyHidden>
            {currentIndex === index ? `Current slide, ${index + 1} of ${items.length}` : `Go to slide ${index + 1}`}
          </VisuallyHidden>
        </Dot>
      ))}
    </DotContainer>
  </ThemeProvider>
);

export default NavigationDots;
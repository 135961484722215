import React, { useState, useEffect } from 'react';
import {
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Fade,
  Grow,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

import ToggleChat from './ToggleChat';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import ConnectionStatus from './ConnectionStatus';

import useChatWebSocket from '../api/websocket';

const ChatWrapper = () => {
  const [isChatVisible, setChatVisibility] = useState(false);
  const [isChatWide, setChatWide] = useState(false);

  const {
    isAuthenticated,
    messages,
    isLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
    isConnected
  } = useChatWebSocket();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleChat = () => setChatVisibility(!isChatVisible);
  const toggleChatWidth = () => setChatWide(!isChatWide);

  return (
    <AnimatePresence>
      {isChatVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.3 }}
        >
          <Box
            sx={{
              position: 'fixed',
              bottom: isMobile ? 0 : 50,
              right: isMobile ? 0 : 10,
              width: isMobile ? '100%' : isChatWide ? '1200px' : '400px',
              height: isMobile ? '100%' : isChatWide ? '700px' : '600px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
              borderRadius: isMobile ? 0 : '20px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#ffffff',
              zIndex: 999,
              transition: 'all 0.3s ease-in-out',
            }}
          >
            <ChatHeader
              isMobile={isMobile}
              toggleChat={toggleChat}
              toggleChatWidth={toggleChatWidth}
              isChatWide={isChatWide}
              isLoading={isLoading}
              wishlistItems={wishlistItems}
              onWishlistUpdate={handleWishlistUpdate}
              onSendMessage={(message) => handleSend(message)}
              isHumanControlled={isHumanControlled}
              humanControlMessage={humanControlMessage}
            />

            <Box 
              sx={{ 
                position: 'relative',
                flexGrow: 1, 
                overflow: isConnected ? 'auto' : 'hidden',
                p: 0,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Fade in={isConnected} timeout={500}>
                <Box style={{ display: isConnected ? 'block' : 'none', height: '100%' }}>
                <ChatBody
                  messages={messages}
                  isLoading={isLoading}
                  onSendMessage={handleSend}
                  onSendFile={(file, message) => handleSend(message, file)}
                  onSendPersonalData={(personaldata) => handleSend("Dati personali salvati", null, null, personaldata)}
                  onWishlistUpdate={handleWishlistUpdate}
                  isConnected={isConnected}
                  isAuthenticated={isAuthenticated}
                  isHumanControlled={isHumanControlled}
                />
                </Box>
              </Fade>
              <Fade in={!isConnected} timeout={500}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    backdropFilter: 'blur(5px)',
                  }}
                >
                  <Grow in={!isConnected} timeout={800}>
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main }}>
                      {isReconnecting ? 'Riconnessione in corso...' : 'Connessione persa'}
                    </Typography>
                  </Grow>
                  <ConnectionStatus 
                    isConnected={isConnected}
                    isReconnecting={isReconnecting}
                    reconnectCountdown={reconnectCountdown}
                    reconnectAttempts={reconnectAttempts}
                    MAX_RECONNECT_ATTEMPTS={MAX_RECONNECT_ATTEMPTS}
                    onManualReconnect={handleManualReconnect}
                  />
                </Box>
              </Fade>
            </Box>

            <Fade in={isConnected} timeout={500}>
              <Box style={{ display: isConnected ? 'block' : 'none' }}>
              <ChatFooter
                onSendMessage={handleSend}
                onSendFile={(file, message) => handleSend(message, file)}
                onSendAudio={(audio, message) => handleSend(message, null, audio)}
                wishlistItems={wishlistItems}
                onWishlistUpdate={handleWishlistUpdate}
                onResendLastMessage={handleResendLastMessage}
                isReconnecting={isReconnecting}
                disabled={isLoading || !isAuthenticated}
              />
              </Box>
            </Fade>
          </Box>
        </motion.div>
      )}
      {!isChatVisible && (
        <ToggleChat isChatVisible={isChatVisible} toggleChat={toggleChat} />
      )}
    </AnimatePresence>
  );
};

export default ChatWrapper;
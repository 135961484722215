// theme.jsx
import { createTheme } from '@mui/material/styles';

const baseTheme = {
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_COLOR || '#0073e6',
      dark: process.env.REACT_APP_PRIMARY_DARK_COLOR || '#0061cc',
      light: process.env.REACT_APP_PRIMARY_LIGHT_COLOR || '#4da3ff',
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_COLOR || '#ff4081',
      dark: process.env.REACT_APP_SECONDARY_DARK_COLOR || '#c60055',
      light: process.env.REACT_APP_SECONDARY_LIGHT_COLOR || '#ff79b0',
    },
    background: {
      default: process.env.REACT_APP_BACKGROUND_COLOR || '#f5f5f5',
      paper: process.env.REACT_APP_PAPER_COLOR || '#ffffff',
    },
    text: {
      primary: process.env.REACT_APP_TEXT_PRIMARY_COLOR || '#333333',
      secondary: process.env.REACT_APP_TEXT_SECONDARY_COLOR || '#757575',
    },
    error: {
      main: process.env.REACT_APP_ERROR_COLOR || '#f44336',
    },
    warning: {
      main: process.env.REACT_APP_WARNING_COLOR || '#ff9800',
    },
    info: {
      main: process.env.REACT_APP_INFO_COLOR || '#2196f3',
    },
    success: {
      main: process.env.REACT_APP_SUCCESS_COLOR || '#4caf50',
    },
  },
  typography: {
    fontFamily: process.env.REACT_APP_FONT_FAMILY || "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: parseInt(process.env.REACT_APP_FONT_SIZE) || 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: process.env.REACT_APP_H1_FONT_SIZE || '2.5rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: process.env.REACT_APP_H2_FONT_SIZE || '2rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: process.env.REACT_APP_H3_FONT_SIZE || '1.75rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: process.env.REACT_APP_H4_FONT_SIZE || '1.5rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: process.env.REACT_APP_H5_FONT_SIZE || '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: process.env.REACT_APP_H6_FONT_SIZE || '1rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: process.env.REACT_APP_SUBTITLE1_FONT_SIZE || '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: process.env.REACT_APP_SUBTITLE2_FONT_SIZE || '0.875rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: process.env.REACT_APP_BODY1_FONT_SIZE || '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: process.env.REACT_APP_BODY2_FONT_SIZE || '0.875rem',
      fontWeight: 400,
    },
    button: {
      fontSize: process.env.REACT_APP_BUTTON_FONT_SIZE || '0.875rem',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: process.env.REACT_APP_CAPTION_FONT_SIZE || '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: process.env.REACT_APP_OVERLINE_FONT_SIZE || '0.75rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    inputFontSize: process.env.REACT_APP_INPUT_FONT_SIZE || '16px',
  },
  shape: {
    borderRadius: parseInt(process.env.REACT_APP_BORDER_RADIUS) || 4,
    inputBorderRadius: process.env.REACT_APP_INPUT_BORDER_RADIUS || '30px',
  },
  spacing: (factor) => `${8 * factor}px`,
  customSpacing: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    footerPadding: process.env.REACT_APP_FOOTER_PADDING || '8px',
    footerPaddingMobile: process.env.REACT_APP_FOOTER_PADDING_MOBILE || '4px',
    inputPadding: process.env.REACT_APP_INPUT_PADDING || '10px 15px',
    buttonMargin: process.env.REACT_APP_BUTTON_MARGIN || '13px',
  },
  sizing: {
    buttonSize: process.env.REACT_APP_BUTTON_SIZE || '40px',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    mobile: process.env.REACT_APP_BREAKPOINT_MOBILE || '600px',
    tablet: process.env.REACT_APP_BREAKPOINT_TABLET || '960px',
    desktop: process.env.REACT_APP_BREAKPOINT_DESKTOP || '1280px',
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
};

export const themeColors = {
  ...baseTheme.palette,
  borderColor: process.env.REACT_APP_BORDER_COLOR || '#e0e0e0',
  chipBackground: process.env.REACT_APP_CHIP_BACKGROUND || '#e0e0e0',
  chipColor: process.env.REACT_APP_CHIP_COLOR || '#333333',
  promotionBackground: process.env.REACT_APP_PROMOTION_BACKGROUND || '#f0f4c3',
  promotionButtonBackground: process.env.REACT_APP_PROMOTION_BUTTON_BACKGROUND || '#cddc39',
  chat: {
    background: process.env.REACT_APP_CHAT_BACKGROUND_COLOR || '#f5f5f5',
    header: process.env.REACT_APP_CHAT_HEADER_COLOR || '#ffffff',
    body: process.env.REACT_APP_CHAT_BODY_COLOR || '#ffffff',
    footer: process.env.REACT_APP_CHAT_FOOTER_COLOR || '#ffffff',
    boxShadow: process.env.REACT_APP_CHAT_BOX_SHADOW_COLOR || 'rgba(0, 0, 0, 0.1)',
  },
  user: {
    background: process.env.REACT_APP_BODY_USER_BACKGROUND_COLOR || '#e3f2fd',
    text: process.env.REACT_APP_BODY_USER_TEXT_COLOR || '#333333',
  },
  server: {
    background: process.env.REACT_APP_BODY_SERVER_BACKGROUND_COLOR || '#f5f5f5',
    backgroundLight: process.env.REACT_APP_BODY_SERVER_BACKGROUND_LIGHT_COLOR || '#fafafa',
    text: process.env.REACT_APP_BODY_SERVER_TEXT_COLOR || '#333333',
  },
  header: {
    primary: process.env.REACT_APP_HEADER_PRIMARY_COLOR || '#0073e6',
    secondary: process.env.REACT_APP_HEADER_SECONDARY_COLOR || '#ff4081',
    background: process.env.REACT_APP_HEADER_BACKGROUND_COLOR || '#ffffff',
    avatarBackground: process.env.REACT_APP_HEADER_AVATAR_BG_COLOR || '#bbdefb',
    avatarColor: process.env.REACT_APP_HEADER_AVATAR_COLOR || '#0073e6',
    text: process.env.REACT_APP_HEADER_TEXT_COLOR || '#333333',
    icon: process.env.REACT_APP_HEADER_ICON_COLOR || '#757575',
  },
  footer: {
    background: process.env.REACT_APP_FOOTER_BACKGROUND_COLOR || '#ffffff',
    inputBackground: process.env.REACT_APP_FOOTER_INPUT_BG_COLOR || '#f0f2f5',
    inputBackgroundFocused: process.env.REACT_APP_FOOTER_INPUT_BG_FOCUSED_COLOR || '#e8f0fe',
    inputFocusShadow: process.env.REACT_APP_FOOTER_INPUT_FOCUS_SHADOW_COLOR || 'rgba(98, 0, 234, 0.2)',
    buttonBackground: process.env.REACT_APP_FOOTER_BUTTON_BG_COLOR || '#0073e6',
    buttonBackgroundHover: process.env.REACT_APP_FOOTER_BUTTON_HOVER_COLOR || '#0061cc',
    buttonColor: process.env.REACT_APP_FOOTER_BUTTON_COLOR || '#ffffff',
  },
  toggle: {
    primary: process.env.REACT_APP_TOGGLE_PRIMARY_COLOR || '#0073e6',
    primaryDark: process.env.REACT_APP_TOGGLE_PRIMARY_DARK_COLOR || '#0061cc',
  },
};

export const createAppTheme = () => createTheme(baseTheme);

export const themebody = {
  colors: {
    ...baseTheme.palette,
    ...themeColors,
  },
  typography: "'Roboto',sans-serif",
  shape: baseTheme.shape,
  spacing: baseTheme.spacing,
  customSpacing: baseTheme.customSpacing,
  sizing: baseTheme.sizing,
  breakpoints: baseTheme.breakpoints,
  zIndex: baseTheme.zIndex,
  transitions: baseTheme.transitions,
};
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Box, Typography, CircularProgress, Button, Fade } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactMarkdown from 'react-markdown';
import { FaRegSmile, FaRegFrown, FaRegMeh, FaFileAudio, FaFileImage, FaFileAlt } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import CustomCarousel from "./CustomCarousel";
import { themebody } from "./theme";
import FeedbackPopup from "./FeedbackPopup";
import PersonalDataForm from './PersonalData';
import { 
  OuterContainer, 
  ChatContainer, 
  MessageContainer, 
  MessagePaper, 
  CustomButton, 
  EmojiButton, 
  FileImage, 
  FileAudio,
  FileOther,
  FileIcon,
  FileName,
  MessageGroup,
  MessageWrapper,
  MessageContent,
  LoadingMessage,
  ToolProgress,
  FileContainer,
  EmojiContainer,
  ButtonContainer
} from "./ChatBodyStyle";

import toolDisplayMapping from "../utils/toolDisplayMapping";

const ConnectionStatus = ({ 
  isConnected, 
  isReconnecting, 
  reconnectCountdown, 
  reconnectAttempts, 
  MAX_RECONNECT_ATTEMPTS, 
  onManualReconnect 
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (isReconnecting) {
      setIsVisible(true);
      const dotsInterval = setInterval(() => {
        setDots(prev => (prev.length < 3 ? prev + '.' : ''));
      }, 500);
      return () => clearInterval(dotsInterval);
    } else if (!isConnected) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
      const hideTimeout = setTimeout(() => setIsVisible(false), 3000);
      return () => clearTimeout(hideTimeout);
    }
  }, [isConnected, isReconnecting]);

  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Box 
        sx={{ 
          p: 2, 
          m: 2,
          borderRadius: 2, 
          bgcolor: 'background.paper', 
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: isConnected ? 'success.main' : isReconnecting ? 'warning.main' : 'error.main' }}>
          {isConnected ? <WifiIcon /> : isReconnecting ? <RefreshIcon className="spin" /> : <WifiOffIcon />}
          <Typography variant="body1">
            {isConnected ? 'Connected' : isReconnecting ? 'Reconnecting' : 'Disconnected'}
          </Typography>
        </Box>

        {isReconnecting && (
          <>
            <Typography variant="body2">
              Attempt {reconnectAttempts} of {MAX_RECONNECT_ATTEMPTS}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} />
              <Typography variant="body2">
                Retrying in {reconnectCountdown}s{dots}
              </Typography>
            </Box>
          </>
        )}

        {!isConnected && !isReconnecting && (
          <Button
            variant="contained"
            size="small"
            startIcon={<RefreshIcon />}
            onClick={onManualReconnect}
          >
            Retry Connection
          </Button>
        )}
      </Box>
    </motion.div>
  );
};

const ChatBody = ({ 
  messages, 
  isLoading, 
  locale, 
  onSendMessage, 
  onWishlistUpdate, 
  onSendPersonalData,
  isConnected,
  isAuthenticated,
  isHumanControlled
}) => {
  const [showButtons, setShowButtons] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [feedbackMessageId, setFeedbackMessageId] = useState(null);
  const [mimeTypes, setMimeTypes] = useState({});
  const messageEndRef = useRef(null);
  const lastHumanControlledIndex = useRef(-1);
  const [visibleMessages, setVisibleMessages] = useState([]);
  const observerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    messages.forEach(async (message, index) => {
      if (!message.id) {
        message.id = `message-${index}`;
      }
      if (message.file_url) {
        const mimeType = await checkMimeType(getFullFileUrl(message.file_url));
        setMimeTypes((prev) => ({ ...prev, [message.file_url]: mimeType }));
      }
    });
  }, [messages]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const messageId = entry.target.getAttribute('data-message-id');
            setVisibleMessages((prev) => [...new Set([...prev, messageId])]);
          }
        });
      },
      { threshold: 0.1, rootMargin: '100px' }
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const messageElements = document.querySelectorAll('[data-message-id]');
    messageElements.forEach((el) => {
      if (observerRef.current) {
        observerRef.current.observe(el);
      }
    });

    return () => {
      if (observerRef.current) {
        messageElements.forEach((el) => {
          observerRef.current.unobserve(el);
        });
      }
    };
  }, [messages]);

  useEffect(() => {
    if (isHumanControlled) {
      lastHumanControlledIndex.current = messages.length - 1;
    }
  }, [isHumanControlled, messages]);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCleanChat = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleButtonClick = (message) => {
    setShowButtons(false);
    onSendMessage(message);
  };

  const handleFeedbackClick = (emoji, messageId) => {
    setSelectedEmoji(emoji);
    setFeedbackMessageId(messageId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEmoji(null);
    setFeedbackMessageId(null);
  };

  const handleModalSubmit = (feedback) => {
    console.log(`Feedback: ${selectedEmoji}, ${feedback}, Message ID: ${feedbackMessageId}`);
    // Handle the submitted feedback here (e.g., send to a server)
  };

  const getFullFileUrl = useCallback((fileUrl) => {
    if (fileUrl.startsWith('blob:')) {
      return fileUrl;
    }
    const domain = process.env.REACT_APP_DOMAIN || '';
    return `${domain}${fileUrl}`;
  }, []);

  const getFileType = useCallback((fileUrl) => {
    let extension;
    if (fileUrl.startsWith('blob:')) {
      extension = 'blob';
    } else {
      extension = fileUrl.split('.').pop().toLowerCase();
    }
    
    if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'blob'].includes(extension)) {
      return 'image';
    } else if (['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(extension)) {
      return 'audio';
    } else {
      return 'other';
    }
  }, []);

  const checkMimeType = useCallback(async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const contentType = response.headers.get('Content-Type');
      return contentType;
    } catch (error) {
      console.error('Error checking MIME type:', error);
    }
  }, []);

  const renderFile = useCallback((fileUrl, fileName) => {
    const fullFileUrl = getFullFileUrl(fileUrl);
    let fileType = getFileType(fileUrl);
    const mimeType = mimeTypes[fileUrl];
    
    if (mimeType && mimeType.includes("audio")) {
      fileType = "audio";
    } else if (mimeType && mimeType.includes("image")) {
      fileType = "image";
    } else {
      fileType = "other";
    }
    
    switch (fileType) {
      case 'audio':
        return (
          <FileAudio controls>
            <source src={fullFileUrl} type={mimeType || "audio/mpeg"} />
            Your browser does not support the audio element.
          </FileAudio>
        );
      case 'image':
        return <FileImage src={fullFileUrl} alt={fileName} />;
      default:
        return (
          <FileOther>
            <FileIcon>
              <FaFileAlt />
            </FileIcon>
            <FileName>{fileName}</FileName>
          </FileOther>
        );
    }
  }, [getFullFileUrl, getFileType, mimeTypes]);

  const renderMessage = useCallback((message, index) => {
    const isVisible = true; // Temporarily set to true to ensure messages render
    return (
      <Fade in={isVisible} timeout={500}>
        <MessageWrapper 
          key={index} 
          sender={message.sender}
          data-message-id={message.id}
        >
          <MessageContent>
            {((message.loading && !isHumanControlled) || message.text || message.file_url) && (
              <MessagePaper sender={message.sender}>
                {message.loading && !isHumanControlled ? (
                  <LoadingMessage>
                    {message.tool && message.tool.length > 0 ? (
                      <>
                        <span>{`${message.tool.map(tool => toolDisplayMapping[tool] || tool).join(', ')}`}</span>
                        {message.status === 'tool' ? (
                          <CircularProgress size={28} color="inherit" />
                        ) : (
                          <ToolProgress />
                        )}
                      </>
                    ) : (
                      <>
                        <CircularProgress size={28} color="inherit" />
                        <span>Sto elaborando</span>
                      </>
                    )}
                  </LoadingMessage>
                ) : (
                  <>
                    {(message.text || message.file_url) && (
                      <ReactMarkdown>{message.text}</ReactMarkdown>
                    )}
                    {message.file_url && (
                      <FileContainer>
                        {renderFile(message.file_url, message.file_name)}
                      </FileContainer>
                    )}
                  </>
                )}
              </MessagePaper>
            )}
            {message.sender !== 'user' && (
              <EmojiContainer sender={message.sender}>
                <EmojiButton onClick={() => handleFeedbackClick("smile", message.id)}>
                  <FaRegSmile />
                </EmojiButton>
                <EmojiButton onClick={() => handleFeedbackClick("meh", message.id)}>
                  <FaRegMeh />
                </EmojiButton>
                <EmojiButton onClick={() => handleFeedbackClick("frown", message.id)}>
                  <FaRegFrown />
                </EmojiButton>
              </EmojiContainer>
            )}
          </MessageContent>
          {message.carouselItems && (
            <CustomCarousel 
              items={message.carouselItems} 
              messageId={message.id} 
              whishlist={message.whishlist}
              onWishlistUpdate={onWishlistUpdate}
              style={{ marginTop: '16px' }}
            />
          )}
          {message.tool === 'customer_registration' && (
            <PersonalDataForm
              onSubmit={onSendPersonalData} 
              onSendMessage={onSendMessage}
            />
          )}
        </MessageWrapper>
      </Fade>
    );
  }, [isHumanControlled, renderFile, onWishlistUpdate, onSendPersonalData, onSendMessage]);

  const renderMessages = useCallback(() => {
    let currentSender = null;
    let messageGroup = [];
    const groupedMessages = [];
    
    
    messages.forEach((message, index) => {
      if (message.text === "Dati personali aggiornati" || message.text === "Dati personali salvati") {
        return;
      }

      
      
      const shouldRenderMessage = message.text?.length > 0 || (message.tool && message.tool?.includes('car_stock_search')) || message.file_url?.length > 0;
      
      if (message.loading && index <= lastHumanControlledIndex.current) {
        return;
      }
      
      if (!shouldRenderMessage && !(message.loading && !isHumanControlled)) {
        return;
      }
  
      if ((!message.loading || isHumanControlled) && message.sender !== currentSender) {
        if (messageGroup.length > 0) {
          groupedMessages.push(
            <MessageGroup key={`group-${currentSender}-${index}`}>
              <AnimatePresence>
                {messageGroup.map((msg, idx) => (
                  <motion.div
                    key={`${msg.key}-motion`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: idx * 0.1 }}
                  >
                    {msg}
                  </motion.div>
                ))}
              </AnimatePresence>
            </MessageGroup>
          );
          messageGroup = [];
        }
        currentSender = message.sender;
      }
  
      messageGroup.push(renderMessage(message, index));
    });
  
    if (messageGroup.length > 0) {
      groupedMessages.push(
        <MessageGroup key={`group-${currentSender}-last`}>
          <AnimatePresence>
            {messageGroup.map((msg, idx) => (
              <motion.div
                key={`${msg.key}-motion`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, delay: idx * 0.1 }}
              >
                {msg}
              </motion.div>
            ))}
          </AnimatePresence>
        </MessageGroup>
      );
    }
  
    return groupedMessages;
  }, [messages, isHumanControlled, lastHumanControlledIndex, renderMessage]);

  return (
    <ThemeProvider theme={themebody}>
      <OuterContainer>
        <ChatContainer>
          <ConnectionStatus 
            isConnected={isConnected}
            isReconnecting={false}
            reconnectCountdown={0}
            reconnectAttempts={0}
            MAX_RECONNECT_ATTEMPTS={5}
            onManualReconnect={() => {}}
          />
          {isConnected && (
            <>
              <MessageContainer sender="system">
                <MessagePaper sender="system">
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ marginBottom: '20px', color: 'white' }}
                  >
                    Benvenuto su {localStorage.getItem("name_chat")}!
                  </motion.h2>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '30px' }}
                  >
                    {localStorage.getItem("welcome_message")}
                  </motion.p>
                  
                  {showButtons ? (
                    <ButtonContainer>
                      {["message_1", "message_2", "message_3"].map((msg, index) => (
                        <motion.div
                          key={msg}
                          initial={{ opacity: 0, x: -50 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.5, delay: 0.6 + index * 0.2 }}
                        >
                          <CustomButton onClick={() => handleButtonClick(localStorage.getItem(msg))}>
                            {localStorage.getItem(msg)}
                          </CustomButton>
                        </motion.div>
                      ))}
                    </ButtonContainer>
                  ) : (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <CustomButton onClick={handleCleanChat}>
                        Pulisci chat
                      </CustomButton>
                    </motion.div>
                  )}
                </MessagePaper>
              </MessageContainer>
              {renderMessages()}
              <div ref={messageEndRef} />
            </>
          )}
        </ChatContainer>
        <FeedbackPopup
          isOpen={isModalOpen}
          onRequestClose={handleModalClose}
          onSubmit={handleModalSubmit}
          selectedEmoji={selectedEmoji}
          messageId={feedbackMessageId}
        />
      </OuterContainer>
    </ThemeProvider>
  );
};

export default ChatBody;

import React from 'react';
import App from './App';  
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const renderChatWidget = (elementId) => {
  const targetElement = document.getElementById(elementId);

  if (targetElement) {
    const root = ReactDOM.createRoot(targetElement);
    root.render(<App />);
  } else {
    console.error(`Element with ID ${elementId} not found!`);
  }
};

// Expose the renderChatWidget function to the global window object
window.renderChatWidget = renderChatWidget;
*/
// components/Theme.jsx
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themebody } from './theme';

const CustomThemeProvider = ({ children }) => (
  <ThemeProvider theme={themebody}>
    {children}
  </ThemeProvider>
);

export default CustomThemeProvider;

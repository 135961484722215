import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { mapItems, propertyMappings } from '../utils/utils';
import { handleWhislist } from './api';

const WEBSOCKET_URL = 'wss://stage-admin.aitomotivelab.com/ws/ai_response/';
const TOKEN_URL = 'https://stage-admin.aitomotivelab.com/user_management/oauth/token/';
const SET_COOKIES_URL = 'https://stage-admin.aitomotivelab.com/ai_app/set_cookies/';

const api = axios.create();

let areCookiesSet = false;
let client_id = null;
let client_secret = null;
let grant_type = null;
let api_token = null;
let form_registration_id = null;
let accessToken = null;

let primary_color = null;
let secondary_color = null;
let background_color = null;
let text_color = null;
let logo = null;
let name_chat = null;

const useChatWebSocket = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [chatId, setChatId] = useState(() => {
    const storedChatId = localStorage.getItem('aitomotivelab_personalData_chat_id');
    return storedChatId && storedChatId !== 'undefined' && storedChatId !== 'null' ? storedChatId : null;
  });
  const chatIdRef = useRef(chatId);

  useEffect(() => {
    chatIdRef.current = chatId;
    if (chatId && (!localStorage.getItem('aitomotivelab_personalData_chat_id') || localStorage.getItem('aitomotivelab_personalData_chat_id') === 'undefined' || localStorage.getItem('aitomotivelab_personalData_chat_id') === 'null')) {
      localStorage.setItem('aitomotivelab_personalData_chat_id', chatId);
    }
  }, [chatId]);

  const [wishlistItems, setWishlistItems] = useState([]);
  const [error, setError] = useState(null);
  const lastUserMessage = useRef('');
  const currentMessageRef = useRef(null);
  const websocketRef = useRef(null);
  const [isHumanControlled, setIsHumanControlled] = useState(false);
  const [humanControlMessage, setHumanControlMessage] = useState('');

  const [isReconnecting, setIsReconnecting] = useState(false);
  const reconnectTimeoutRef = useRef(null);
  const reconnectAttempts = useRef(0);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_INTERVAL = 5000; // 5 seconds

  const [reconnectCountdown, setReconnectCountdown] = useState(0);

  const initializeApi = async () => {
    if (areCookiesSet) return;

    try {
      const response = await api.post(SET_COOKIES_URL);
      client_id = response.data.client_id;
      client_secret = response.data.client_secret;
      grant_type = response.data.grant_type;
      api_token = response.data.api_token;
      form_registration_id = response.data.form_registration_id;
      primary_color = response.data.primary_color;
      secondary_color = response.data.secondary_color;
      background_color = response.data.background_color;
      text_color = response.data.text_color;
      logo = response.data.logo;
      name_chat = response.data.name;

      areCookiesSet = true;
      localStorage.setItem('api_token', api_token);
      localStorage.setItem('primary_color', primary_color);
      localStorage.setItem('secondary_color', secondary_color);
      localStorage.setItem('background_color', background_color);
      localStorage.setItem('text_color', text_color);
      localStorage.setItem('logo', logo);
      localStorage.setItem('name_chat', name_chat);
      localStorage.setItem('company_name', response.data.company_name);
      localStorage.setItem('welcome_message', response.data.welcome_message);
      localStorage.setItem('message_1', response.data.message_1);
      localStorage.setItem('message_2', response.data.message_2);
      localStorage.setItem('message_3', response.data.message_3);
    } catch (error) {
      console.error('Error initializing API:', error);
      setError('Failed to initialize API. Please refresh the page.');
    }
  };

  const getAccessToken = async () => {
    if (accessToken) {
      return accessToken;
    }

    try {
      const response = await api.post(
        TOKEN_URL,
        {
          client_id: client_id,
          client_secret: client_secret,
          grant_type: grant_type,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      accessToken = response.data.access_token;
      return accessToken;
    } catch (error) {
      console.error('Error obtaining access token:', error);
      throw new Error('Failed to obtain access token');
    }
  };

  const initializeWebSocket = useCallback(async () => {
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    try {
      await initializeApi();
      const token = await getAccessToken();

      websocketRef.current = new WebSocket(WEBSOCKET_URL);

      websocketRef.current.onopen = () => {
        setIsConnected(true);
        setIsReconnecting(false);
        reconnectAttempts.current = 0;
        setError(null);
        websocketRef.current.send(
          JSON.stringify({
            type: 'authenticate',
            token: token,
          })
        );
      };

      websocketRef.current.onmessage = handleWebSocketMessage;

      websocketRef.current.onclose = (event) => {
        setIsConnected(false);
        setIsAuthenticated(false);
        if (event.code !== 1000) {
          setError('WebSocket disconnected. Attempting to reconnect...');
          attemptReconnect();
        }
      };

      websocketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
        setError('WebSocket connection error. Attempting to reconnect...');
        attemptReconnect();
      };
    } catch (error) {
      console.error('Error initializing WebSocket:', error);
      setError(
        error.message || 'Failed to initialize chat. Attempting to reconnect...'
      );
      attemptReconnect();
    }
  }, []);

  const attemptReconnect = useCallback(() => {
    if (isReconnecting) return;

    setIsReconnecting(true);
    reconnectAttempts.current += 1;

    if (reconnectAttempts.current <= MAX_RECONNECT_ATTEMPTS) {
      const delay = RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts.current - 1);
      setReconnectCountdown(Math.floor(delay / 1000));

      reconnectTimeoutRef.current = setTimeout(() => {
        initializeWebSocket();
      }, delay);
    } else {
      console.log('Max reconnection attempts reached');
      setError('Failed to reconnect after multiple attempts. Please try again.');
      setIsReconnecting(false);
    }
  }, [initializeWebSocket, isReconnecting]);

  useEffect(() => {
    let countdownInterval;
    if (reconnectCountdown > 0) {
      countdownInterval = setInterval(() => {
        setReconnectCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
    };
  }, [reconnectCountdown]);

  const handleManualReconnect = () => {
    reconnectAttempts.current = 0;
    setError(null);
    initializeWebSocket();
  };

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [initializeWebSocket]);

  const handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    console.log('Received data:', data);
  
    if (data.type === 'authentication') {
      setIsAuthenticated(data.success);
      if (!data.success) {
        setError('Authentication failed. Please refresh the page.');
      } else {
        const storedChatId = localStorage.getItem('aitomotivelab_personalData_chat_id');
        if (storedChatId && storedChatId !== 'undefined' && storedChatId !== 'null') {
          websocketRef.current.send(
            JSON.stringify({
              type: 'join_chat',
              chat_id: storedChatId,
            })
          );
        } else {
          websocketRef.current.send(
            JSON.stringify({
              type: 'create_chat',
            })
          );
        }
      }
    } else if (data.type === 'chat_created' || data.type === 'chat_joined') {
      if (!chatIdRef.current) {
        setChatId(data.chat_id);
        localStorage.setItem('aitomotivelab_personalData_chat_id', data.chat_id);
      }
      fetchChatHistory();
    } else if (data.type === 'error' && data.message === 'Chat not found') {
      setChatId(null);
      chatIdRef.current = null;
      websocketRef.current.send(
        JSON.stringify({
          type: 'create_chat',
        })
      );
    } else if (data.type === 'chat_history') {
      let parsedHistory;
      try {
        parsedHistory = JSON.parse(data.chat_history);
      } catch (parseError) {
        setError('Invalid chat history format');
        return;
      }
      const formattedHistory = parsedHistory.flatMap((item) =>
        formatChatHistoryItem(item.fields, item.pk)
      );
      setMessages(formattedHistory);
      updateWishlistFromMessages(formattedHistory);
    } else if (data.type === 'stream_message' || data.type === 'message_complete' || data.type === 'admin_message') {
      handleIncomingMessage(data);
    } else if (data.type === 'human_control_update') {
      setIsHumanControlled(data.human_control);
      setHumanControlMessage(data.message || 'Un operatore è ora disponibile.');
    }
  };

  const fetchChatHistory = () => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      websocketRef.current.send(
        JSON.stringify({
          type: 'fetch_history',
        })
      );
    } else {
      setError('Failed to fetch chat history. Please try again.');
    }
  };

  const formatChatHistoryItem = (message, id) => {
    const formattedMessages = [];
    if (message.sender_message !== null && message.sender_message !== 'Dati personali salvati') {
      formattedMessages.push({
        sender: 'user',
        text: message.sender_message,
        id,
        file_url: message.sender_file_url || null,
      });
    } else if (message.sender_file_url) {
      // Add a user message for file-only uploads
      formattedMessages.push({
        sender: 'user',
        text: 'File inviato',  // Or any appropriate text for file uploads
        id,
        file_url: message.sender_file_url,
      });
    }
  
    if (message.bot_message || (message.tool && message.tool.includes('car_stock'))) {
      let formattedMessage = {
        sender: 'server',
        tool: message.tool || null,
        text: message.bot_message || '',
        id,
        file_url: message.bot_file_url || null,
        whishlist: message.whishlist,
      };
      
      if (
        message.sources &&
        message.tool &&
        message.tool.includes('car_stock')
      ) {
        console.log("message", message);
        try {
          if (Array.isArray(message.sources) && propertyMappings[message.tool]) {
            console.log("+++++++++car_stock_search");
            console.log(message.sources);
            console.log(message.cars);
            formattedMessage.carouselItems = mapItems(
              message.sources,
              propertyMappings[message.tool]
            );
          }
        } catch (error) {
          console.warn('Error processing carousel items:', error);
        }
      }
  
      formattedMessages.push(formattedMessage);
    }
  
    return formattedMessages;
  };

  const updateWishlistFromMessages = useCallback((messages) => {
    const wishlistMap = new Map();

    messages.forEach((message) => {
      if (Array.isArray(message.carouselItems) && message.whishlist) {
        const wishlistIds = message.whishlist.split(',').map((id) => id.trim());
        message.carouselItems.forEach((item) => {
          const isInWishlist = wishlistIds.includes(item.vehicleid.toString());
          if (isInWishlist) {
            wishlistMap.set(item.vehicleid, {
              ...item,
              messageId: message.id,
            });
          } else {
            wishlistMap.delete(item.vehicleid);
          }
        });
      }
    });

    const uniqueWishlist = Array.from(wishlistMap.values());

    setWishlistItems(uniqueWishlist);
  }, []);

  const handleWishlistUpdate = async (messageId, vehicleId, newState) => {
    try {
      const response = await handleWhislist(messageId, vehicleId);

      if (response) {
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((message) => {
            let wishlistArray = message.whishlist
              ? message.whishlist.split(',').map((id) => id.trim())
              : [];

            if (newState) {
              wishlistArray = [...new Set([...wishlistArray, vehicleId.toString()])];
            } else {
              wishlistArray = wishlistArray.filter(
                (id) => id !== vehicleId.toString()
              );
            }

            return { ...message, whishlist: wishlistArray.join(',') };
          });

          updateWishlistFromMessages(updatedMessages);

          return updatedMessages;
        });
      } else {
        throw new Error('Failed to update wishlist');
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
      setError('Failed to update wishlist. Please try again.');
    }
  };

  const handleIncomingMessage = useCallback(
    (data) => {
      setIsLoading(false);
      setError(null);

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        let currentMessage;

        if (data.type === 'stream_message') {
          if (data.status === 'tool') {
            const toolName = data.message;
            const lastMessage = newMessages[newMessages.length - 1] || {};
            const updatedToolList = Array.isArray(lastMessage.tool)
              ? [...new Set([...lastMessage.tool, toolName])]
              : [toolName];
            currentMessage = {
              ...lastMessage,
              tool: updatedToolList,
              loading: true,
              status: data.status,
            };
            if (newMessages.length > 0) {
              newMessages[newMessages.length - 1] = currentMessage;
            } else {
              newMessages.push(currentMessage);
            }
          } else {
            if (currentMessageRef.current) {
              currentMessage = {
                ...currentMessageRef.current,
                text: currentMessageRef.current.text + data.message,
                loading: true,
              };
            } else {
              currentMessage = {
                sender: 'server',
                text: data.message,
                tool: [],
                id: data.id,
                loading: true,
              };
              newMessages.push(currentMessage);
            }
            currentMessageRef.current = currentMessage;
          }
        } else if (data.type === 'message_complete' || data.type === 'admin_message') {
          const lastMessage = newMessages[newMessages.length - 1] || {};
          currentMessage = {
            ...lastMessage,
            text: data.message,
            loading: false,
            status: undefined,
            tool: data.tool || [],
            id: data.id,
          };

          if (!currentMessage.id) {
            console.error('Server did not provide an ID for the complete message');
          }

          if (data.sources && Array.isArray(data.sources)) {
            if (data.tool && data.tool.includes('car_stock_search')) {
              try {
                currentMessage.carouselItems = mapItems(
                  data.sources,
                  propertyMappings[data.tool]
                );
              } catch (mappingError) {
                console.warn('Error mapping items:', mappingError);
              }
            } else {
              currentMessage.carouselItems = data.sources;
            }
          }
          currentMessage.whishlist = data.whishlist || '';

          if (newMessages.length > 0 && data.type !== 'admin_message') {
            newMessages[newMessages.length - 1] = currentMessage;
          } else if (data.type === 'admin_message') {
            newMessages.push(currentMessage);
          } else {
            newMessages.push(currentMessage);
          }
          currentMessageRef.current = null;
        }

        updateWishlistFromMessages(newMessages);
        return newMessages;
      });
    },
    [updateWishlistFromMessages]
  );

  const handleSend = async (message, file, audio, personaldata) => {
    if (!isAuthenticated) {
      setError('Not authenticated. Please try again.');
      return;
    }

    setError(null);
    let localUrl = null;
    let userMessage = {
      sender: 'user',
      text: message || 'Ti ho inviato un messaggio',
    };

    if (file) {
      localUrl = URL.createObjectURL(file);
      userMessage.file_url = localUrl;
    } else if (audio) {
      localUrl = URL.createObjectURL(audio);
      userMessage.file_url = localUrl;
    }

    if (!personaldata) {
      setMessages((prevMessages) => [
        ...prevMessages,
        userMessage,
        { sender: 'server', text: '', loading: true },
      ]);
      lastUserMessage.current = message;
      currentMessageRef.current = { sender: 'server', text: '', loading: true };
    }

    const data = {
      type: file
        ? 'file_message'
        : audio
        ? 'audio_message'
        : 'chat_message',
      input: message,
      file: file ? await fileToBase64(file) : null,
      file_name: file ? file.name : null,
      file_type: file ? file.type : null,
      audio: audio ? await fileToBase64(audio) : null,
      personaldata: personaldata,
      ...getPersonalDataFromLocalStorage(),
    };

    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      websocketRef.current.send(JSON.stringify(data));
    } else {
      setError('WebSocket is not connected. Attempting to reconnect...');
      setIsLoading(false);
      attemptReconnect();
    }
  };

  const handleResendLastMessage = () => {
    if (lastUserMessage.current) {
      handleSend(lastUserMessage.current);
    }
  };

  const getPersonalDataFromLocalStorage = () => ({
    first_name: localStorage.getItem('aitomotivelab_personalData_first_name') || '',
    last_name: localStorage.getItem('aitomotivelab_personalData_last_name') || '',
    email: localStorage.getItem('aitomotivelab_personalData_email') || '',
    gdpr_consent:
      localStorage.getItem('aitomotivelab_personalData_gdpr') === 'gdpr',
    marketing_consent:
      localStorage.getItem('aitomotivelab_personalData_marketing') === 'marketing',
    phone: localStorage.getItem('aitomotivelab_personalData_phone') || '',
    api_token: localStorage.getItem('api_token') || '',
  });

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return {
    isAuthenticated,
    isConnected,
    messages,
    isLoading,
    wishlistItems,
    error,
    isReconnecting,
    reconnectCountdown,
    reconnectAttempts: reconnectAttempts.current,
    MAX_RECONNECT_ATTEMPTS,
    handleManualReconnect,
    handleSend,
    handleResendLastMessage,
    handleWishlistUpdate,
    isHumanControlled,
    humanControlMessage,
  };
};

export default useChatWebSocket;
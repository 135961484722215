export const fetchVehicleData = async (vehicleId) => {
    try {
      const response = await fetch(`https://apicore.smiledealer.it/vehicles/stocks/140/${vehicleId}/large/`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
      return null;
    }
  };
  
import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogContent, DialogTitle, Typography, IconButton, CardMedia,
  Tabs, Tab, useMediaQuery, Grid, Box, Button, Skeleton, Chip, CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, ArrowBackIos, ArrowForwardIos, Info, Directions, Fullscreen } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import Accessories from './Accessories';  // Make sure to create this as a separate file

// Custom theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_BODY_PRIMARY_COLOR || "#FF3B30",
      dark: process.env.REACT_APP_BODY_PRIMARY_DARK_COLOR || "#C1271B",
    },
    secondary: {
      main: process.env.REACT_APP_BODY_SECONDARY_COLOR || "#005BBB",
    },
    background: {
      default: process.env.REACT_APP_BODY_BACKGROUND_COLOR || "#F2F2F7",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: process.env.REACT_APP_BODY_BUTTON_BACKGROUND_COLOR || "#005BBB",
          color: process.env.REACT_APP_BODY_BUTTON_TEXT_COLOR || "#FFFFFF",
        },
      },
    },
  },
});

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

const defaultImage = 'https://aitomotivelab.com/media/popup_chat/non_disp.jpeg';

// Styled components
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

// TabPanel component
const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box sx={{ p: 4 }}>{children}</Box>}
  </div>
);

// FullScreenImage component
const FullScreenImage = ({ image, open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullScreen>
    <IconButton
      onClick={onClose}
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        color: 'white',
        zIndex: 2,
      }}
    >
      <CloseIcon />
    </IconButton>
    <CardMedia
      component="img"
      image={image}
      sx={{ width: '100%', height: '100%', objectFit: 'contain', bgcolor: 'black' }}
    />
  </Dialog>
);

// Updated ImageGallery component with prominent discount
const ImageGallery = ({ images, onError, retailPrice, salePrice }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePrev = () => setCurrentIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  const handleNext = () => setCurrentIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  const handleImageLoad = () => setIsLoading(false);
  const handleFullScreenOpen = () => setIsFullScreen(true);
  const handleFullScreenClose = () => setIsFullScreen(false);

  useEffect(() => {
    setIsLoading(true);
  }, [currentIndex]);

  const discountPercentage = retailPrice && salePrice 
    ? Math.round((1 - salePrice / retailPrice) * 100) 
    : null;

  return (
    <Box sx={{ position: 'relative', mb: 2, height: 300 }}>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.5 }}
          style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {isLoading && (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
          <CardMedia
            component="img"
            height="300"
            image={images[currentIndex]}
            alt={`Car ${currentIndex + 1}`}
            onError={onError}
            onLoad={handleImageLoad}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              transition: 'transform 0.3s',
              '&:hover': { transform: 'scale(1.05)' },
              display: isLoading ? 'none' : 'block',
            }}
            onClick={handleFullScreenOpen}
          />
          {salePrice && (
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                left: 16,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {retailPrice>0 && retailPrice > salePrice  && (
                <Typography
                  variant="body2"
                  sx={{
                    textDecoration: 'line-through',
                    mb: 0.5,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '2px 6px',
                    borderRadius: '4px',
                  }}
                >
                  {`${formatPrice(retailPrice)} €`}
                </Typography>
              )}
              <Chip
                label={`${formatPrice(salePrice)} €`}
                color="secondary"
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                  padding: '16px 8px',
                }}
              />
            </Box>
          )}
          {discountPercentage && (
            <Chip
              label={`-${discountPercentage}%`}
              color="error"
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                fontWeight: 'bold',
                fontSize: '1.2rem',
                padding: '16px 8px',
              }}
            />
          )}
        </motion.div>
      </AnimatePresence>
      <IconButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
      <IconButton
        onClick={handleFullScreenOpen}
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
        }}
      >
        <Fullscreen />
      </IconButton>
      <FullScreenImage
        image={images[currentIndex]}
        open={isFullScreen}
        onClose={handleFullScreenClose}
      />
    </Box>
  );
};

// GeneralInfo component
const GeneralInfo = ({ vehicleData }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Grid container spacing={2}>
      {[
        { label: 'Anno di Immatricolazione', value: vehicleData.registrationYear, icon: <Info />, check: true },
        { label: 'Colore', value: vehicleData.colour, icon: <Info /> },
        { label: 'Chilometri', value: formatPrice(vehicleData.km), icon: <Directions />, check: true },
        { label: 'Prezzo di Listino', value: formatPrice(vehicleData.retailPrice), icon: <Info />, check: true },
        { label: 'Prezzo di Vendita', value: formatPrice(vehicleData.salePrice), icon: <Info />, check: true },
        { label: 'Gruppo Modello', value: vehicleData.modelGroup, icon: <Info /> },
        { label: 'Carburante', value: vehicleData.fuel, icon: <Info /> },
        { label: 'Trasmissione', value: vehicleData.transmission, icon: <Info /> },
        { label: 'Porte', value: vehicleData.doors, icon: <Info /> },
        { label: 'Posti', value: vehicleData.seats, icon: <Info /> },
        { label: 'Classe di Emissione', value: vehicleData.emissionClass, icon: <Info /> },
        { label: 'Cavalli', value: vehicleData.hp, icon: <Info /> },
        { label: 'Emissioni CO2', value: vehicleData.cO2EmissionCombined, icon: <Info /> },
      ]
        .filter(item => {
          if (item.check) {
            return parseFloat(item.value) > 0;
          }
          return item.value && item.value.toString().trim() !== '';
        })
        .map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              {item.icon}
              <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 'bold', ml: 1 }}>
                {item.label}
              </Typography>
            </Box>
            <Typography variant="body1" color="text.primary">
              {item.label === 'Prezzo di Listino' ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textDecoration: 'line-through' }}
                >
                  {`${item.value} €`}
                </Typography>
              ) : item.label === 'Prezzo di Vendita' ? (
                `${item.value} €`
              ) : item.label === 'Emissioni CO2' ? (
                `${item.value} g/km`
              ) : (
                item.value
              )}
            </Typography>
          </Grid>
        ))}
    </Grid>
  </motion.div>
);

// Main VehicleDialog component
const VehicleDialog = ({ open, handleClose, vehicleData }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [images, setImages] = useState([]);
  const fullScreen = useMediaQuery(customTheme.breakpoints.down('md'));

  useEffect(() => {
    if (vehicleData && vehicleData.urlMainImage) {
      const baseUrl = vehicleData.urlMainImage.replace(/\/\d+\/large$/, '');
      setImages([0, 1, 2].map(index => `${baseUrl}/${index}/large`));
    } else {
      setImages([defaultImage]);
    }
  }, [vehicleData]);

  const handleImageError = (event) => {
    event.target.src = defaultImage;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullWidth 
        fullScreen={fullScreen} 
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: '15px',
            overflow: 'hidden',
          }
        }}
      >
        <StyledDialogTitle>
          <Typography variant="h6">Dettagli del Veicolo</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent sx={{ p: 0, bgcolor: 'background.paper' }}>
          {vehicleData ? (
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <ImageGallery
                images={images}
                onError={handleImageError}
                retailPrice={vehicleData.retailPrice}
                salePrice={vehicleData.salePrice}
              />
              <Tabs
                value={tabIndex}
                onChange={(_, newValue) => setTabIndex(newValue)}
                centered
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <StyledTab label="Informazioni Generali" />
                <StyledTab label="Accessori" />
              </Tabs>
              <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
                <TabPanel value={tabIndex} index={0}>
                  <GeneralInfo vehicleData={vehicleData} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                <Accessories
                    standardAccessories={vehicleData.standardAccessories}
                    optionalAccessories={vehicleData.optionalAccessories}
                  />
                </TabPanel>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 3 }}>
              <Skeleton variant="rectangular" height={300} sx={{ mb: 2, borderRadius: '10px' }} />
              <Skeleton variant="text" height={40} sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {[...Array(8)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton variant="text" height={24} />
                    <Skeleton variant="text" height={24} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default VehicleDialog;
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Button, Chip, IconButton, useTheme } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import VehicleDialog from './VehicleDialog';
import NavigationDots from './NavigationDots';
import { fetchVehicleData } from '../api/fetchVehicleData';
import './CustomCarousel.css';
import { FaTachometerAlt, FaEuroSign, FaCalendarAlt, FaTags, FaHeart } from 'react-icons/fa';

// Helper function to safely access nested properties
const getThemeValue = (theme, path, fallback) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], theme) || fallback;
};

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

const CustomCarousel = ({ items, messageId, whishlist, onWishlistUpdate }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [open, setOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);
  const [wishlistState, setWishlistState] = useState({});
  const theme = useTheme();

  useEffect(() => {
    const wishlistIds = whishlist ? whishlist.split(',').map(id => id.trim()) : [];
    const newWishlist = items.reduce((acc, item) => {
      acc[item.vehicleid] = wishlistIds.includes(item.vehicleid.toString());
      return acc;
    }, {});
    setWishlistState(newWishlist);
  }, [whishlist, items]);

  const handleWishlist = useCallback(async (vehicleId) => {
    const newWishlistState = !wishlistState[vehicleId];
    setWishlistState(prev => ({ ...prev, [vehicleId]: newWishlistState }));
    try {
      await onWishlistUpdate(messageId, vehicleId, newWishlistState);
    } catch (error) {
      console.error('Wishlist update failed:', error);
      setWishlistState(prev => ({ ...prev, [vehicleId]: !newWishlistState }));
    }
  }, [wishlistState, messageId, onWishlistUpdate]);
  
  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? 1 : -1);
    setCurrentIndex(index);
  };

  const handleOpenVehiclePopup = (index) => {
    setOpen(true);
    fetchVehicleData(items[index].vehicleid).then(setVehicleData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (items.length === 0) return null;

  return (
    <>
      <Box
        className="carousel-container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          marginBottom: '40px',
          margin:'10px',
          backgroundColor: getThemeValue(theme, 'palette.background.default', '#f5f5f5'),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', overflow: 'hidden', marginBottom: '30px' }}>
          {items.length > 1 && (
            <IconButton
              className="carousel-arrow prev-arrow"
              onClick={handlePrev}
              aria-label="Previous"
              sx={{
                position: 'absolute',
                left: 10,
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
              }}
            >
              <ArrowBackIos sx={{ color: 'white' }} />
            </IconButton>
          )}
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              initial={{ x: direction > 0 ? 1000 : -1000, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: direction < 0 ? 1000 : -1000, opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="carousel"
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              {items.map((item, index) => (
                <Box
                  key={index}
                  className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                  sx={{ display: index === currentIndex ? 'block' : 'none', width: '100%', maxWidth: '400px' }}
                >
                  <Card
                    key={`${item.vehicleid}-${index}`}
                    className="custom-card"
                    sx={{
                      margin: '0 0px',
                      boxShadow: 2,
                      borderRadius: '15px',
                      overflow: 'hidden',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      backgroundColor: getThemeValue(theme, 'palette.background.paper', '#ffffff'),
                      borderBottom: `0px solid ${getThemeValue(theme, 'palette.divider', '#e0e0e0')}`,
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <CardMedia
                        component="img"
                        height="180"
                        image={item.image || 'https://aitomotivelab.com/media/popup_chat/non_disp.jpeg'}
                        alt="Car"
                        sx={{ objectFit: 'cover' }}
                      />
                      <IconButton
                        onClick={() => handleWishlist(item.vehicleid)}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                          transition: 'all 0.3s ease',
                        }}
                      >
                        <FaHeart 
                          color={wishlistState[item.vehicleid] ? 'red' : 'rgba(0, 0, 0, 0.1)'} 
                          style={{ transition: 'all 0.3s ease' }}
                        />
                      </IconButton>
                    </Box>
                    {item.badge && (
                      <Chip
                        label={item.badge}
                        color="primary"
                        className="card-chip"
                        sx={{
                          position: 'absolute',
                          top: 8,
                          left: 8,
                          zIndex: 1,
                          background: getThemeValue(theme, 'palette.primary.main', '#0073e6'),
                          color: getThemeValue(theme, 'palette.primary.contrastText', '#ffffff'),
                          fontSize: '0.7rem',
                          height: '24px',
                        }}
                      />
                    )}
                    <CardContent sx={{ flexGrow: 1, padding: '12px' }}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                          fontWeight: 'bold',
                          color: getThemeValue(theme, 'palette.text.primary', '#000000'),
                          fontSize: '1.1rem',
                          marginBottom: '8px',
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Box sx={{ display: 'flex', marginBottom: '4px' }}>
                        {item.description1 && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ 
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              color: getThemeValue(theme, 'palette.text.secondary', '#757575'),
                              fontSize: '0.85rem',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: 'auto',
                            }}
                          >
                            <FaTachometerAlt style={{ marginRight: '4px', fontSize: '0.85rem' }} /> {formatPrice(item.description1)} 
                          </Typography>
                        )}
                        {item.description3 && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ 
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              color: getThemeValue(theme, 'palette.text.secondary', '#757575'),
                              fontSize: '0.85rem',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: item.description1 ? 'auto' : '0',
                            }}
                          >
                            <FaCalendarAlt style={{ marginRight: '4px', fontSize: '0.85rem' }} /> {item.description3}
                          </Typography>
                        )}
                      </Box>
                      {item.description2 && (
                        <Typography
                          variant="h6"
                          color="text.secondary"
                          sx={{ 
                            fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                            color: getThemeValue(theme, 'palette.text.secondary', '#757575'),
                            fontSize: '2rem',
                            marginBottom: '6px',
                            fontWeight: 900,
                          }}
                        >
                          <FaEuroSign style={{ marginRight: '-10px', fontSize: '1.5rem' }} />  {formatPrice(item.description2)}
                        </Typography>
                      )}
                      {item.description4>0 && item.description4>0 && parseFloat(item.description4) > parseFloat(item.description2) && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <Typography
                            variant="body2"
                            sx={{
                              textDecoration: 'line-through',
                              color: getThemeValue(theme, 'palette.text.secondary', '#757575'),
                              marginRight: '8px',
                            }}
                          >
                            <FaEuroSign style={{ marginRight: '2px', fontSize: '0.8rem' }} />
                            {formatPrice(item.description4)}
                          </Typography>
                          <Chip
                            label={`-${Math.round((1 - parseFloat(item.description2) / parseFloat(item.description4)) * 100)}%`}
                            color="error"
                            size="small"
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '0.8rem',
                            }}
                          />
                        </Box>
                      )}
                      {item.promotion && (
                        <Box
                          sx={{
                            mt: 1,
                            p: 1,
                            background: (theme) => {
                              const primaryColor = getThemeValue(theme, 'palette.primary.main', '#0073e6');
                              const secondaryColor = getThemeValue(theme, 'palette.secondary.main', '#7b1fa2');
                              return `linear-gradient(135deg, rgb(224, 195, 252) 30%, rgb(142, 197, 252) 90%)`;
                            },
                            borderRadius: '8px',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              fontWeight: 'bold',
                              color: "black",
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '0.9rem',
                            }}
                          >
                            <FaTags style={{ marginRight: '4px', fontSize: '0.8rem', color: '#6A0572' }} /> Scopri la promozione!
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              color: 'black',
                              fontWeight:"400",
                              marginTop: '4px',
                              fontSize: '0.8rem',
                            }}
                          >
                            {item.descrizione_economica_promozione_ai}
                          </Typography>
                          <Button
                            size="small"
                            color="primary"
                            href={item.link_promozione_promozione_ai !== 'NULL' ? item.link_promozione_promozione_ai : '#'}
                            target="_blank"
                            variant="contained"
                            sx={{
                              mt: 1,
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              borderRadius: '20px',
                              boxShadow: 1,
                              background: getThemeValue(theme, 'palette.primary.main', '#0073e6'),
                              color: 'white',
                              transition: 'transform 0.2s',
                              '&:hover': { transform: 'scale(1.05)' },
                              fontSize: '0.7rem',
                              padding: '4px 8px',
                            }}
                          >
                            Scopri di più
                            </Button>
                            </Box>
                          )}
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
                          <Button
                            size="small"
                            color="primary"
                            href={item.url}
                            target="_blank"
                            variant="contained"
                            sx={{
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              borderRadius: '20px',
                              boxShadow: 1,
                              background: getThemeValue(theme, 'palette.primary.main', '#0073e6'),
                              color: 'white',
                              fontSize: '0.7rem',
                              padding: '4px 8px',
                            }}
                          >
                            Apri Pagina
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => handleOpenVehiclePopup(index)}
                            variant="outlined"
                            sx={{
                              fontFamily: getThemeValue(theme, 'typography.fontFamily', "'Roboto', 'Helvetica', 'Arial', sans-serif"),
                              borderRadius: '20px',
                              borderColor: getThemeValue(theme, 'palette.primary.main', '#0073e6'),
                              color: getThemeValue(theme, 'palette.primary.main', '#0073e6'),
                              borderWidth: 1,
                              borderStyle: 'solid',
                              '&:hover': {
                                borderColor: getThemeValue(theme, 'palette.primary.dark', '#0061cc'),
                                backgroundColor: 'rgba(0, 91, 187, 0.1)',
                              },
                              fontSize: '0.7rem',
                              padding: '4px 8px',
                            }}
                          >
                            Dettagli
                          </Button>
                        </Box>
                      </Card>
                    </Box>
                  ))}
                </motion.div>
              </AnimatePresence>
              {items.length > 1 && (
                <IconButton
                  className="carousel-arrow next-arrow"
                  onClick={handleNext}
                  aria-label="Next"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  <ArrowForwardIos sx={{ color: 'white' }} />
                </IconButton>
              )}
            </Box>
            <NavigationDots items={items} currentIndex={currentIndex} handleDotClick={handleDotClick} />
          </Box>
          <VehicleDialog open={open} handleClose={handleClose} vehicleData={vehicleData} />
        </>
      );
    };
    
    export default CustomCarousel;

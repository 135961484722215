import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Avatar, Box, Badge, useTheme } from '@mui/material';
import { Close as CloseIcon, SmartToy as AIIcon, Person as PersonIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { FaExpandArrowsAlt, FaCompressArrowsAlt, FaHeart } from 'react-icons/fa';
import styled from 'styled-components';
import CustomThemeProvider from './ThemeWrapper';
import WishlistPopup from './WishlistPopup';

const HeaderContainer = styled(motion.div)`
  background-color: ${props => props.theme.colors.background.paper};
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  z-index: 1000;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.colors.primary.main};
  color: ${props => props.theme.colors.text.primary};
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  padding: ${props => props.theme.spacing(1, 2)};
`;

const AnimatedAvatar = styled(motion(Avatar))`
  width: 40px;
  height: 40px;
  margin-right: ${props => props.theme.spacing(2)};
`;

const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.isOnline ? '#4CAF50' : '#FFA000'};
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid white;
`;

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.colors.text.primary};
`;

const ChatHeader = ({
  isMobile,
  toggleChat,
  toggleChatWidth,
  isChatWide,
  wishlistItems,
  onWishlistUpdate,
  onSendMessage,
  isLoading,
  isHumanControlled,
  humanControlMessage,
  isOnline
}) => {
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [typingEffect, setTypingEffect] = useState('');
  const [showControlMessage, setShowControlMessage] = useState(false);
  const theme = useTheme();

  const handleWishlistToggle = () => setIsWishlistOpen(!isWishlistOpen);

  useEffect(() => {
    setShowControlMessage(true);
    let message = isHumanControlled
      ? humanControlMessage || "Un operatore è ora disponibile."
      : "MIA può commettere errori, verifica le informazioni.";

    let i = 0;
    const typing = setInterval(() => {
      setTypingEffect(message.slice(0, i));
      i++;
      if (i > message.length) {
        clearInterval(typing);
      }
    }, 50);

    return () => clearInterval(typing);
  }, [isHumanControlled, humanControlMessage]);

  return (
    <CustomThemeProvider>
      <HeaderContainer
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <StyledAppBar position="static" elevation={3}>
          <StyledToolbar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AnimatedAvatar
                src={localStorage.getItem("logo")}
                alt="Chat Logo"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <StatusIndicator isOnline={isOnline} />
              </AnimatedAvatar>
              <Box>
                <Typography variant="h6">
                  {localStorage.getItem("name_chat")}
                </Typography>
                <Typography variant="caption">
                  {isHumanControlled ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Operatore online
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AIIcon fontSize="small" sx={{ marginRight: 0.5 }} />
                      Assistente AI
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AnimatePresence>
                {wishlistItems.length > 0 && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0 }}
                  >
                    <Badge badgeContent={wishlistItems.length} color="secondary">
                      <StyledIconButton
                        aria-label="wishlist"
                        onClick={handleWishlistToggle}
                        disabled={isLoading}
                      >
                        <FaHeart />
                      </StyledIconButton>
                    </Badge>
                  </motion.div>
                )}
              </AnimatePresence>
              {!isMobile && (
                <StyledIconButton onClick={toggleChatWidth}>
                  {isChatWide ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                </StyledIconButton>
              )}
              <StyledIconButton onClick={toggleChat}>
                <CloseIcon />
              </StyledIconButton>
            </Box>
          </StyledToolbar>
        </StyledAppBar>
        <AnimatePresence>
          {showControlMessage && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Box sx={{
                padding: theme.spacing(1, 2),
                backgroundColor: isHumanControlled ? 'rgba(76, 175, 80, 0.1)' : 'rgba(33, 150, 243, 0.1)',
                textAlign: 'center'
              }}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {typingEffect}
                </Typography>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </HeaderContainer>
      <WishlistPopup
        open={isWishlistOpen}
        handleClose={() => setIsWishlistOpen(false)}
        wishlistItems={wishlistItems}
        onWishlistUpdate={onWishlistUpdate}
        onSendMessage={onSendMessage}
      />
    </CustomThemeProvider>
  );
};

export default ChatHeader;
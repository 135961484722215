import axios from 'axios';
import { mapItems, propertyMappings } from '../utils/utils';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

// Constants

const DOMAIN = 'https://stage-admin.aitomotivelab.com/';
const API_URL = 'https://stage-admin.aitomotivelab.com/ai_app';
const TOKEN_URL = 'https://stage-admin.aitomotivelab.com/user_management/oauth/token/';
const SET_COOKIES_URL = 'https://stage-admin.aitomotivelab.com/ai_app/set_cookies/';
const FORMBUILDER_API_URL = `${DOMAIN}formbuilder/api/`;


const api = axios.create({
});

let accessToken = null;
let ffmpeg = null;
let areCookiesSet = false;
let client_id = false;
let client_secret = false;
let grant_type = false;
let api_token = false;
let form_registration_id = false;

const logErrorDetails = (error) => {
  if (error.response) {
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
  } else if (error.request) {
    console.error('Request data:', error.request);
  } else {
    console.error('Error message:', error.message);
  }
};

export const initializeApi = async () => {
  if (areCookiesSet) return; // Skip initialization if already done
  
  try {
    // Set cookies
    const response = await api.post(SET_COOKIES_URL);
    //console.log(response.data)
    client_id = response.data.client_id
    client_secret = response.data.client_secret
    grant_type = response.data.grant_type
    api_token = response.data.api_token
    form_registration_id = response.data.form_registration_id
    // Mark cookies as set
    areCookiesSet = true;

    localStorage.setItem('api_token', api_token)
    
    //console.log('API initialized successfully');
  } catch (error) {
    console.error('Error initializing API:', error);
  }
};



const ensureCookiesAndExecute = async (requestFn) => {
  // Check if cookies are set, initialize if not
  if (!areCookiesSet) {
    await initializeApi();
  }
  
  // Execute the request function passed as an argument
  return requestFn();
};

export const getAccessToken = async () => {

  if (accessToken) {
    return accessToken;
  }

  try {
    
    const response = await api.post(TOKEN_URL, 
      {
        "client_id" : client_id,
        "client_secret" : client_secret,
        "grant_type":grant_type
      },

      {
        headers: {
          
          'Content-Type': 'application/x-www-form-urlencoded'
        
      },
      
    }      
    );
    accessToken = response.data.access_token;
    return accessToken;
  } catch (error) {
    console.error('Error obtaining access token:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw new Error('Failed to obtain access token');
  }
};


const getPersonalDataFromLocalStorage = () => ({
  first_name: localStorage.getItem('aitomotivelab_personalData_first_name') || '',
  last_name: localStorage.getItem('aitomotivelab_personalData_last_name') || '',
  email: localStorage.getItem('aitomotivelab_personalData_email') || '',
  gdpr_consent: localStorage.getItem('aitomotivelab_personalData_gdpr') === 'gdpr',
  marketing_consent: localStorage.getItem('aitomotivelab_personalData_marketing') === 'marketing',
  phone: localStorage.getItem('aitomotivelab_personalData_phone') || '',
  api_token: api_token || ''
});

const loadFFmpeg = async () => {
  if (ffmpeg === null) {
    ffmpeg = new FFmpeg();
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.11.0/dist/umd';
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
  }
  return ffmpeg;
};

const resizeImage = async (file, maxWidth, maxHeight, targetSize) => {
  let quality = 0.9;
  let resizedFile;

  do {
    resizedFile = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: file.type }));
            },
            file.type,
            quality
          );
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });

    quality -= 0.05;
  } while (resizedFile.size > targetSize && quality > 0.1);

  return resizedFile;
};

const compressAudio = async (file, targetSize) => {
  const ffmpeg = await loadFFmpeg();
  
  await ffmpeg.writeFile(file.name, await fetchFile(file));
  
  // Calculate bitrate based on target size (assuming a 5-minute audio file)
  const targetBitrate = Math.floor((targetSize * 8) / (5 * 60)) + 'k';
  
  await ffmpeg.exec(['-i', file.name, '-b:a', targetBitrate, 'output.mp3']);
  const data = await ffmpeg.readFile('output.mp3');
  return new File([data], 'compressed_audio.mp3', { type: 'audio/mpeg' });
};

const reduceFileSize = async (file) => {
  const TARGET_SIZE = 1 * 1024 * 1024; // 1MB

  if (file.size <= TARGET_SIZE) {
    return file;
  }

  if (file.type.startsWith('image/')) {
    return resizeImage(file, 1024, 1024, TARGET_SIZE);
  } else if (file.type.startsWith('audio/')) {
    return compressAudio(file, TARGET_SIZE);
  } else {
    console.warn(`File type ${file.type} not supported for size reduction. File size: ${file.size} bytes`);
    if (file.size > 5 * TARGET_SIZE) {
      throw new Error(`File is too large (${(file.size / (1024 * 1024)).toFixed(2)} MB) and cannot be reduced.`);
    }
    return file;
  }
};

export const sendMessage = async (message, chatId = 'default_chat_id') => {
  if (!message || typeof message !== 'string') {
    throw new Error('Invalid message format');
  }

  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const data = { chat_id: chatId, input: message, ...personalData };

    try {
      const response = await api.post(`${API_URL}/chat/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const responseData = response?.data;

      if (!responseData || typeof responseData !== 'object' || !responseData.message) {
        throw new Error('Invalid response data');
      }

      let sources = responseData.sources;
      if (typeof sources === 'string') {
        try {
          sources = JSON.parse(sources);
        } catch (jsonError) {
          console.warn('Error parsing sources JSON:', jsonError);
          sources = [];
        }
      }

      if (Array.isArray(sources) && responseData.tool && responseData.tool.includes('car_stock_search')) {
        try {
          const items = mapItems(sources, propertyMappings[responseData.tool]);
          return { message: responseData.message, items, id: responseData.id };
        } catch (mappingError) {
          console.warn('Error mapping items:', mappingError);
          return { message: responseData.message, id: responseData.id };
        }
      }

      return { message: responseData.message, id: responseData.id, tool: responseData.tool };
    } catch (error) {
      console.error('Error sending message:', error);
      logErrorDetails(error);
      throw new Error('Failed to send message');
    }
  });
};

export const sendFile = async (message, file, chatId = 'default_chat_id') => {
  if (!file) {
    throw new Error('No file provided');
  }

  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const formData = new FormData();
    formData.append('chat_id', chatId);
    formData.append('input', message);
    Object.entries(personalData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    let fileToSend;
    if (file instanceof File) {
      fileToSend = await reduceFileSize(file);
    } else if (file instanceof Blob) {
      fileToSend = await reduceFileSize(new File([file], 'file.name'));
    } else if (typeof file === 'string' && file.indexOf('base64,') !== -1) {
      const base64Data = file.split('base64,')[1];
      const blob = b64toBlob(base64Data);
      fileToSend = await reduceFileSize(new File([blob], 'file.name'));
    } else {
      throw new Error('Invalid file format');
    }

    formData.append('file', fileToSend);

    try {
      const response = await api.post(`${API_URL}/chat/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response?.data;

      if (!responseData || typeof responseData !== 'object' || !responseData.message) {
        throw new Error('Invalid response data');
      }

      let sources = responseData.sources;
      if (typeof sources === 'string') {
        try {
          sources = JSON.parse(sources);
        } catch (jsonError) {
          console.warn('Error parsing sources JSON:', jsonError);
          sources = [];
        }
      }
      
      if (Array.isArray(sources) && responseData.tool && responseData.tool.includes('car_stock_search')) {
        try {
          
          const items = mapItems(sources.cars, propertyMappings[responseData.tool]);
          return { message: responseData.message, items, file_path: responseData.file_path, id: responseData.id };
        } catch (mappingError) {
          console.warn('Error mapping items:', mappingError);
          return { message: responseData.message, file_path: responseData.file_path, id: responseData.id };
        }
      }

      return { message: responseData.message, file_path: responseData.file_path, id: responseData.id };
    } catch (error) {
      console.error('Error sending file:', error);
      logErrorDetails(error);
      throw new Error('Failed to send file');
    }
  });
};

export const sendAudio = async (audioBlob, chatId = 'default_chat_id') => {
  if (!(audioBlob instanceof Blob)) {
    throw new Error('Invalid audio format');
  }

  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const formData = new FormData();
    formData.append('chat_id', chatId);
    Object.entries(personalData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    
    const audioFile = new File([audioBlob], 'audio_message.wav', { type: 'audio/wav' });
    const compressedAudioFile = await reduceFileSize(audioFile);
    formData.append('file', compressedAudioFile);

    try {
      const response = await api.post(`${API_URL}/chat/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      const responseData = response?.data;

      if (!responseData || typeof responseData !== 'object' || !responseData.message) {
        throw new Error('Invalid response data');
      }

      let sources = responseData.sources;
      if (typeof sources === 'string') {
        try {
          sources = JSON.parse(sources);
        } catch (jsonError) {
          console.warn('Error parsing sources JSON:', jsonError);
          sources = [];
        }
      }

      if (Array.isArray(sources) && responseData.tool && responseData.tool.includes('car_stock_search')) {
        try {
          const items = mapItems(sources, propertyMappings[responseData.tool]);
          return { message: responseData.message, items, audio_path: responseData.file_path, id: responseData.id };
        } catch (mappingError) {
          console.warn('Error mapping items:', mappingError);
          return { message: responseData.message, audio_path: responseData.file_path, id: responseData.id };
        }
      }

      return { message: responseData.message, audio_path: responseData.file_path, id: responseData.id };
    } catch (error) {
      console.error('Error sending audio:', error);
      logErrorDetails(error);
      throw new Error('Failed to send audio');
    }
  });
};



export const fetchChatHistory = async (chatId) => {
  if (!chatId) {
    throw new Error('Chat ID is required');
  }

  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const data = { chat_id: chatId, ...personalData };

    try {
      const response = await api.post(`${API_URL}/chat/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const chatHistory = response?.data?.chat_history;

      if (!chatHistory) {
        throw new Error('Chat history not found in response');
      }

      let parsedHistory;
      try {
        parsedHistory = JSON.parse(chatHistory);
      } catch (parseError) {
        console.error('Error parsing chat history:', parseError);
        throw new Error('Invalid chat history format');
      }

      return parsedHistory.flatMap((item) => formatChatHistoryItem(item.fields, item.pk));
    } catch (error) {
      console.error('Error fetching chat history:', error);
      logErrorDetails(error);
      throw new Error('Failed to fetch chat history');
    }
  });
};

export const submitForm = async () => {
  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    
    const data = {
      ...personalData,
    };

    try {
      const response = await api.post(`${API_URL}/chat/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const chatId = response?.data?.chat_id;

      if (!chatId) {
        throw new Error('Chat ID not found in response');
      }

      return chatId;
    } catch (error) {
      console.error('Error submitting form:', error);
      logErrorDetails(error);
      throw new Error('Failed to submit form');
    }
  });
};

export const handleVote = async (messageId, vote, category = null, comment = null) => {
  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const data = { message_id: messageId, vote, category, comment, ...personalData };

    try {
      const response = await api.post(`${API_URL}/handle_vote/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error handling vote:', error);
      logErrorDetails(error);
      throw new Error('Failed to handle vote');
    }
  });
};

export const handleWhislist = async (messageId, whishlist) => {
  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();
    const personalData = getPersonalDataFromLocalStorage();
    const data = { message_id: messageId, wishlist_entry: whishlist, ...personalData };

    try {
      const response = await api.post(`${API_URL}/whishlist/`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error handling whishlist:', error);
      logErrorDetails(error);
      throw new Error('Failed to handle whishlist');
    }
  });
};

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const formatChatHistoryItem = (message, id) => {
  const formattedMessages = [];
  if (message.sender_message !== null && message.sender_message !== "Dati personali salvati") {
    formattedMessages.push({ sender: 'user', text: message.sender_message, id, file_url: message.sender_file_url || null });
  }

  if (message.bot_message) {
    let formattedMessage = { sender: 'server', tool: message.tool || null, text: message.bot_message, id, file_url: message.bot_file_url || null, whishlist: message.whishlist };

    //console.log("------sources", message.sources)
    if (message.sources && message.tool && message.tool.includes('car_stock_search')) {
      try {
        //const sources = typeof message.sources === 'string' ? JSON.parse(message.sources) : message.sources;
        if (Array.isArray(message.sources) && propertyMappings[message.tool]) {
          formattedMessage.carouselItems = mapItems(message.sources, propertyMappings[message.tool]);
        }
      } catch (error) {
        console.warn('Error processing carousel items:', error);
      }
    }

    formattedMessages.push(formattedMessage);
  }

  return formattedMessages;
};


export const fetchRegistrationForm = async () => {
  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();

    try {
      const response = await api.get(`${FORMBUILDER_API_URL}forms/personal_data/`, {
        headers: {
        'Authorization': `Bearer ${token}`
         },
      });

      if (!response.data) {
        throw new Error('Invalid response data');
      }

      return response;
    } catch (error) {
      console.error('Error fetching registration form:', error);
      logErrorDetails(error);
      throw new Error('Failed to fetch registration form');
    }
  });
};

export const submitFormResponse = async (formId, fieldResponses) => {
  return ensureCookiesAndExecute(async () => {
    const token = await getAccessToken();

    try {
      const response = await api.post(`${FORMBUILDER_API_URL}form-responses/`, 
        { 
          form: formId, 
          field_responses: Array.isArray(fieldResponses) ? fieldResponses : [fieldResponses],
          chat_id: localStorage.getItem("aitomotivelab_personalData_chat_id"), // Using api_token as chat_id
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.data) {
        throw new Error('Invalid response data');
      }

      return response.data;
    } catch (error) {
      console.error('Error submitting form response:', error);
      logErrorDetails(error);
      throw new Error('Failed to submit form response');
    }
  });
};


